<template>
  <div
    :class="[
      $style.playerWrapper,
      {
        [$style.playerWrapper_transparent]: isOne2ManyType && $isCordovaIOS,
        [$style.playerWrapper_blocked]: streamIsBlocked,
      },
    ]"
  >
    <broadcast-blocked-message v-if="streamIsBlocked" :class="$style.blockMessage" />
    <broadcast-player
      v-else
      :key="streamName"
      :class="$style.player"
      :player-type="userPlayerType"
      :player-props="playerConfig.props"
      v-on="playerConfig.listeners"
    />
  </div>
</template>

<script>
import random from "lodash/random";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { auth, broadcast, room, presentation } from "@/store/modules/store.namespaces";
import {
  BROADCAST_PLAYER_TYPE,
  BROADCAST_URL,
  FACECAST_URL,
  IS_CONFERENCE_TYPE,
  IS_FACECAST_TYPE,
  IS_MP4_TYPE,
  IS_ONE_2_MANY_TYPE,
  IS_TYPICAL_TYPE,
  IS_VIDEO_TYPE,
  IS_VIMEO_TYPE,
  IS_YOUTUBE_TYPE,
  ROOM_BROADCAST_TYPE,
  SCREEN_SAVER_SRC,
  SPEAKER_PLAYER_CONSTRAINTS,
  VIMEO_BROADCAST_URL,
  YT_BROADCAST_URL,
} from "@/store/modules/broadcast/getter-types";
import { CURRENT_PRESENTATION } from "@/store/modules/presentation/getter-types";
import {
  NGENIX_URL_POSTPART,
  NGENIX_URL_PREPART,
  PROVIDER_POSITIONS,
  STREAM_TYPES,
} from "@/constants/broadcast/broadcast-const";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";
import {
  ADD_LEADING,
  DELETE_LEADING,
  GET_LIST_OF_LEADING,
  RAISE_HAND,
  UPDATE_LEADING,
} from "@/store/modules/broadcast/action-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import BroadcastPlayer from "@/components/common/broadcast/BroadcastPlayerByType";
import BroadcastBlockedMessage from "@/components/common/broadcast/BroadcastBlockedMessage";
import config from "@/settings/config";

export default {
  name: "ContainerBroadcastPlayersProvider",
  components: {
    BroadcastPlayer,
    BroadcastBlockedMessage,
  },
  data() {
    return {
      playerType: STREAM_TYPES.VIEWER,
      pingTimeout: null,
    };
  },
  computed: {
    ...mapState(broadcast, [
      "onAir",
      "safeMode",
      "onAirAsLeading",
      "resolution",
      "streamName",
      "streamCaptureName",
      "streamServer",
      "speakerState",
      "permissions",
      "showBroadcastSettingsPopup",
      "displayScreenSaver",
      "handsUpBtnDisabled",
      "userPlayerType",
    ]),
    ...mapState(auth, ["user"]),
    ...mapState(room, {
      roomNumber: "roomNumber",
    }),
    ...mapGetters(broadcast, {
      isOne2ManyType: IS_ONE_2_MANY_TYPE,
      isTypicalType: IS_TYPICAL_TYPE,
      isConferenceType: IS_CONFERENCE_TYPE,
      isFacecastType: IS_FACECAST_TYPE,
      isYtType: IS_YOUTUBE_TYPE,
      isVimeoType: IS_VIMEO_TYPE,
      isMp4Type: IS_MP4_TYPE,
      isVideoType: IS_VIDEO_TYPE,
      broadcastType: ROOM_BROADCAST_TYPE,
      broadcastPlayerConstraints: SPEAKER_PLAYER_CONSTRAINTS,
      screenSaverSrc: SCREEN_SAVER_SRC,
      broadcastPlayerType: BROADCAST_PLAYER_TYPE,
      ytBroadcastUrl: YT_BROADCAST_URL,
      vimeoBroadcastUrl: VIMEO_BROADCAST_URL,
      broadcastUrl: BROADCAST_URL,
      facecastUrl: FACECAST_URL,
    }),
    ...mapGetters(presentation, {
      currentPresentation: CURRENT_PRESENTATION,
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    playerConfig() {
      return (
        (this.isConferenceType && this.conferencePlayerConfig) ||
        ((this.isVideoType || this.isTypicalType) && this.videoPlayerConfig) ||
        (this.isOne2ManyType && this.one2ManyPlayerConfig)
      );
    },
    one2ManyPlayerConfig() {
      return {
        props: {
          room: this.roomNumber,
          userAvatar: this.speakerState.avatar,
          speakerVideoMuted: this.speakerState.videoMuted,
          streamName: this.streamName,
          streamCaptureName: this.streamCaptureName,
          urlServer: this.streamServer,
          type: this.playerType,
          displayScreenSaver: this.displayScreenSaver,
          screenSaverSrc: this.screenSaverSrc,
          resolution: this.speakerState.resolution,
          autoplay: true,
        },
        listeners: {
          play: this.sendLogs.bind(this, "Play"),
        },
      };
    },
    conferencePlayerConfig() {
      return {
        props: {
          userName: this.user.fio,
          userId: this.user.id,
          userAvatar: this.user.photoWithUrl,
          type: this.playerType,
          onAirAsLeading: this.onAirAsLeading,
          room: this.roomNumber,
          constraints: this.broadcastPlayerConstraints,
          resolution: this.resolution,
          streamName: this.streamName,
          streamCaptureName: this.streamCaptureName,
          urlServer: this.streamServer,
          canPresent: this.permissions.canPresent,
          autoplay: true,
          audioPermission: this.permissions.audio,
          videoPermission: this.permissions.video,
          handsUpDisabled: this.handsUpBtnDisabled,
        },
        listeners: {
          loaded: this.playerLoaded.bind(this, true),
          failed: this.failedHandler,
          "allow-translation": event => {
            this.playerType = event;
          },
          "toggle-video": this.toggleVideo,
          "toggle-audio": this.toggleAudio,
          "go-on-air": this.openBroadcastPopup,
          "hands-up": this.handsUp,
        },
      };
    },

    /**
     * Метод генерации настроек rtmp-плеера.
     *
     * @returns {object} Настройки rtmp-плеера и его слушатели.
     */
    videoPlayerConfig() {
      return {
        props: {
          ...this.one2ManyPlayerConfig?.props,
          youtube: this.isYtType,
          vimeo: this.isVimeoType,
          mp4: this.isMp4Type,
          typical: this.isTypicalType,
          facecast: this.isFacecastType,
          url:
            (this.isYtType && this.ytBroadcastUrl) ||
            (this.isVimeoType && this.vimeoBroadcastUrl) ||
            (this.isMp4Type && this.broadcastUrl) ||
            (this.isTypicalType && this.rtmpUrl) ||
            (this.isFacecastType && this.facecastUrl) ||
            "",
          autoplay: true,
          multilingual:
            this.availableRoomModules.streamMultilingual && !this.rtmpUrl && this.isTypicalType,
          displayScreenSaver: this.displayScreenSaver,
          screenSaverSrc: this.screenSaverSrc,
        },
        listeners: {
          ...this.one2ManyPlayerConfig?.listeners,
          play: this.sendLogs.bind(this, "Play"),
          pause: this.sendLogs.bind(this, "Pause"),
          seeked: this.sendLogs.bind(this, "TimeUpdate"),
        },
      };
    },
    rtmpUrl() {
      if (this.streamName) {
        if (config.flashphonerHlsUrl) {
          return `${config.flashphonerHlsUrl}/${this.streamName}/${this.streamName}.m3u8`;
        }

        return `${NGENIX_URL_PREPART}rtmp_${this.streamName}${NGENIX_URL_POSTPART}`;
      }
      return "";
    },
    streamIsBlocked() {
      return (
        (this.isConferenceType && this.$isCordovaIOS) ||
        ((this.isOne2ManyType || this.isConferenceType) && this.$isIOSNotSupportWebRTC)
      );
    },
  },
  watch: {
    streamName() {
      this.$notify({
        group: "broadcast",
        type: "warn",
        duration: 5000,
        title: this.$t("broadcast.reconnect"),
        text: this.$t("broadcast.speakerLaunchedNewBroadcast"),
      });
    },
    "permissions.canPresent": function(val) {
      if (!val) {
        this.removeHandsUpBtnDisabled();
      }
    },
    "$fullscreen.isFullscreen": function(val) {
      if (val) return;
      this.moveProviderCorrectPosition();
    },
    $mqLaptop() {
      this.moveProviderCorrectPosition();
    },
  },
  async mounted() {
    this.moveProviderCorrectPosition();
    if (this.isOne2ManyType || this.isTypicalType || this.isMp4Type) {
      await this.sendLogs("Init");
      this.runPing();
    }
    if (this.isConferenceType) {
      await this.conferenceTypeHandler();
    }
  },
  async beforeDestroy() {
    this.$el.remove();
    if (this.isOne2ManyType || this.isTypicalType || this.isMp4Type) {
      await this.sendLogs("Close");
      if (this.pingTimeout) {
        clearTimeout(this.pingTimeout);
      }
    }
    if (this.isConferenceType) {
      window.removeEventListener("unload", this.unloadHandler);
      this.removeHandsUpBtnDisabled();
    }
  },
  methods: {
    ...mapMutations(broadcast, {
      setBroadcastStateProp: BROADCAST_SET_STATE_PROP,
    }),
    ...mapActions(broadcast, {
      raiseHand: RAISE_HAND,
      addLeading: ADD_LEADING,
      getListOfLeading: GET_LIST_OF_LEADING,
      updateLeading: UPDATE_LEADING,
      deleteLeading: DELETE_LEADING,
    }),
    moveProviderCorrectPosition() {
      if (this.$fullscreen.isFullscreen) return;
      const targetPosition = this.$mqLaptop
        ? PROVIDER_POSITIONS.ROOM_SIDE
        : PROVIDER_POSITIONS.ROOM_CONTAINER;
      const targetEl = document.getElementById(PROVIDER_POSITIONS[targetPosition]);
      targetEl.appendChild(this.$el);
    },
    openBroadcastPopup() {
      this.setBroadcastStateProp({
        showBroadcastSettingsPopup: true,
      });
    },
    playerLoaded(state) {
      this.setBroadcastStateProp({
        prepared: state,
      });
    },
    async sendLogs(event) {
      const eventCode = this.$loggers.$stream.Events[event];
      await this.$loggers.$stream.sendLog(
        this.$loggers.$stream.getPayload({
          user: this.user,
          event: eventCode,
          roomNumber: this.roomNumber,
          presId: this.currentPresentation?.id,
        }),
      );
    },
    async handsUp() {
      const hasName = this.user.fio.trim();
      if ((this.user.is_guest && !hasName) || !hasName) {
        this.setBroadcastStateProp({
          requestUserName: true,
        });
      } else {
        await this.raiseHand();
        this.$notify({
          group: "broadcast",
          type: "success",
          duration: 5000,
          title: this.$t("broadcast.requestGoOnAirTitle"),
          text: this.$t("broadcast.requestGoOnAirText"),
        });
      }
    },
    toggleAudio(state) {
      const permissions = {
        ...this.permissions,
        audio: state,
      };
      const leadingState = {
        user_id: this.user.id,
        name: this.user.fio,
        permissions,
      };
      this.setBroadcastStateProp({
        permissions,
      });
      this.updateLeading(leadingState);
    },
    toggleVideo(state) {
      const permissions = {
        ...this.permissions,
        video: state,
      };
      const leadingState = {
        user_id: this.user.id,
        name: this.user.fio,
        permissions,
      };
      this.setBroadcastStateProp({
        permissions,
      });
      this.updateLeading(leadingState);
    },
    failedHandler() {
      this.setBroadcastStateProp({
        onAirAsLeading: false,
      });
    },
    async conferenceTypeHandler() {
      if (this.user.fio.trim()) {
        const listOfLeading = await this.getListOfLeading();
        if (listOfLeading.some(leading => +leading.user_id === this.user.id)) {
          const user = listOfLeading.find(l => +l.user_id === this.user.id);
          if (user) {
            if (user.permissions.canPresent) {
              this.removeHandsUpBtnDisabled();
            }
            this.setBroadcastStateProp({
              permissions: user.permissions,
            });
          }
        } else {
          this.removeHandsUpBtnDisabled();
          await this.addLeading();
        }
      } else {
        this.removeHandsUpBtnDisabled();
      }
    },
    removeHandsUpBtnDisabled() {
      if (this.handsUpBtnDisabled) {
        this.setBroadcastStateProp({
          handsUpBtnDisabled: false,
        });
        localStorage.removeItem("handsUpBtnDisabled");
      }
    },
    async unloadHandler() {
      this.removeHandsUpBtnDisabled();
      await this.deleteLeading();
    },
    runPing() {
      const min = 5;
      const max = 15;
      const timeout = random(min, max) * 60 * 1000;
      this.pingTimeout = setTimeout(() => {
        this.sendLogs("Ping");
        this.runPing();
      }, timeout);
    },
  },
};
</script>

<style lang="scss" module>
.playerWrapper {
  @include aspect-ratio-16x9;

  display: flex;
  background: #000;

  &_blocked {
    padding-top: min(240px, calc((9 / 16) * 100%));
  }

  &_blocked .blockMessage {
    flex-grow: 1;
  }

  &_transparent {
    background: transparent;
  }
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
